@import 'variables';
@import 'main';

a.toggleTheme {
  &:after {
    font-size:13px;
    left: 5px;
    content: '\f186';
  }
}
