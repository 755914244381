@import 'normalize';
@import 'syntax';

@import url('https://fonts.googleapis.com/css?family=Quicksand|Roboto&display=swap');

body {
  background-color: $body-back !important;
  font-family: 'Poppins';
  color: $text;

  & > .container {
    margin: 0px auto;
  }
}

a.toggleTheme {
  position: fixed;
  display: block;
  bottom: 20px;
  left: 20px;
  background: $inverted-background;
  color: $inverted-blog-text;
  font-family: 'fontawesome';
  z-index: 999;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  &:after {
    position: absolute;
    top: 0;
  }
  &:hover {
    color: lighten($inverted-blog-text,15%);
  }
}

a {
  color: $primary;
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
    color: $text;
  }
}
.container .row {
  .sidebar .sticky-top {
    padding-top: 50px;
  }
  .page-body {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.sidebar {
  text-align: center;

  .about {
    width: 100%;
    @include shadow;
    .author-name {
      background: $background;
      padding: 23px;
      font-size: 20px;
      font-weight: 700;
      display: block;
      border-bottom: 1px dashed $divisor;
    }
    .cover-author-image {
      background: transparent !important;
      img {
        width: 100%;
      }
    }
  }

  .menu {
    ul {
      list-style: none;
      padding: 25px;
      margin: 0;
      li {
        display: inline-block;
        margin: 2px 4px;
        a {
          display: block;
          color: $text;
          font-size: 13px;
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .contact {
    background: $background;
    ul {
      list-style: none;
      padding: 20px;
      margin: 0;
      li {
        display: inline-block;
        margin: 2px 4px;
        a {
          display: block;
          width: 30px;
          height: 30px;
          line-height: 32px;
          font-size: 13px;
          color: $social-text;
          border-radius: 50%;

          &.facebook {
            background: $facebook;
          }
          &.twitter {
            background: $twitter;
          }
          &.github {
            background: $github;
          }
          &.linkedin {
            background: $linkedin;
          }
          &.rss {
            background: $rss;
          }
        }
      }
    }

    margin-bottom: 30px;
    @include shadow;
  }
}

.page-body {
  background-color: $background;
  @include shadow;
  .sub-title {
    padding: $padding;
    h1 {
      display: inline-block;
      font-size: 25px;
      letter-spacing: 0.5px;
      font-weight: 600;
    }

    h2 {
      font-size: 35px;
      line-height: 45px;
      font-weight: 700;
    }
  }

  .content-page {
    padding-top: 30px;
    padding-bottom: 10px;
    border-top: 1px dashed $divisor;

    & > .row {
      margin-bottom: 50px;
      border-bottom: 1px dashed $divisor;
      padding-bottom: 50px;
    }

    .post-thumbnail {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .post-content {
      display: block;
      margin-bottom: 50px;
    }

    .blog-post {
      font-size: 15px;
      line-height: 30px;
      color: $blog-text;
      font-weight: 300;
      letter-spacing: 0.8px;

      blockquote {
        background: $primary;
        border-radius: 25px;
        padding: 25px;
        h3 {
          position: relative;
          color: $background;
          font-size: 18px;
          padding: 25px 70px 0px 25px;

          &:before {
            content: '\f10e';
            font-family: 'fontawesome';
            position: absolute;
            font-size: 50px;
            top: 0px;
            right: 15px;
            color: invert($text);
          }
        }
        p {
          color: white;
          text-align: right;
          padding-right: 25px;
          font-size: 14px;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 20px;
        margin-bottom: 10px;
        color: $blog-title;
      }

      h2 {
        font-size: 25px;
        line-height: 35px;
        font-weight: 600;
        letter-spacing: 0.5px;
        
      }
      h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      h4 {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
    }
  }

  .page-footer {
    display: flex;

    .page-share {
      text-align: left;
      a {
        display: inline-block;
        width: $padding;
        margin: 3px;
        color: $social-text;
        border-radius: 5px;
        text-align: center;

        &.facebook {
          background: $facebook;
          &:hover {
            background: lighten($color: $facebook, $amount: 25%);
          }
        }
        &.twitter {
          background: $twitter;
          &:hover {
            background: lighten($color: $twitter, $amount: 25%);
          }
        }
        &.linkedin {
          background: $linkedin;
          &:hover {
            background: lighten($color: $linkedin, $amount: 25%);
          }
        }
      }
    }

    .page-tag {
      a {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        margin: 3px;
        font-size: 10px;
        text-transform: uppercase;
        &.tag {
          background: $primary;
          color: darken($primary, 50%);
          &:hover {
            background: lighten($primary, 25%);
          }
        }
        &.category {
          background: $body-back;
          color: $blog-text;
          &:hover{
            background: lighten($body-back, 25%)
          }
        }
      }
    }
  }
}

/* - - - - - - - - - - Home Page Styles - - - - - - - - - - */
@import 'parts/home-page';

/* - - - - - - - - - - Post Page Styles - - - - - - - - - - */
@import 'parts/post-page';

/* - - - - - - - - - - Tag Styles - - - - - - - - - - */
@import 'parts/tag';

/* - - - - - - - - - - Media Styles - - - - - - - - - - */
@import 'media';
